<template>
  <div class="program-container">
    <div class="program-content-container">
      <h2 class="program-name">
        <markdown-it :text="program.name"/>
      </h2>
      <div class="program-description">
        <div v-if="program.description.length > 250">
          <expandable-markdown-it
            :text="program.description"
            :truncation-length="250"
          />
        </div>
        <div v-else>
          <markdown-it :text="program.description"/>
        </div>
      </div>
      <div class="rewards-container">
        <dashboard-rewards v-if="rewardResponse" :reward-response="rewardResponse"/>
        <div v-if="requiredActivitiesNotCompletedCount > 0" class="rewards-warning">
          <tooltip-wrapper
            :tooltip-text="`Complete all required activities to receive your incentive.
            You have ${requiredActivitiesNotCompletedCount} activities remaining.`"
          >
            <div class="rewards-warning-content">
              <img src="@/assets/img/exclamation-mark.svg" alt="tooltip" class="tooltip-icon"/>
              You have not completed all of your Required Activities to receive your incentive
            </div>
          </tooltip-wrapper>
        </div>
      </div>
    </div>
    <div class="company-logo-container">
      <div v-if="!companyLogo">
        <img
          src="@/assets/img/welcome.svg"
          :srcset="`
      ${smallImage} 600w,
      ${largeImage} 1200w
      `"
          sizes="(max-width: 600px) 100vw, (min-width: 601px) 50vw"
          alt="Responsive Image"
        />
      </div>
      <protected-image-rounded-rectangle v-else :src="companyLogo" alt="Company Logo"/>
      <router-link :to="to" class="program-button">
        Discover My Program
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import DashboardRewards from "@/vue/molecules/dashboard-rewards.vue";
import ProtectedImageRoundedRectangle from "@/vue/atoms/protected-image-rounded-rectangle.vue";
import TooltipWrapper from "@/vue/atoms/tooltip-wrapper.vue";
import type {Program} from "@/ts/types/store/default-store-types";
import type {RewardTrackerResponse} from "@/ts/types/dto/reward-tracker";
import ExpansionPanel from "@/vue/atoms/expansion-panel.vue";
import RightChevron from "@/assets/img/right-chevron.svg";
import UpChevron from "@/assets/img/up-chevron.svg";
import largeImage from "@/assets/img/welcome.svg";
import smallImage from "@/assets/img/welcome-small.svg";
import MarkdownIt from "@/vue/atoms/markdown-it.vue";
import ExpandableMarkdownIt from "@/vue/atoms/expandable-markdown-it.vue";

import IconChameleon from "@/vue/atoms/icon-chameleon.vue";

export default defineComponent({
  name: "ProgramSection",
  components: {
    DashboardRewards,
    ProtectedImageRoundedRectangle,
    TooltipWrapper,
    ExpansionPanel,
    IconChameleon,
    MarkdownIt,
    ExpandableMarkdownIt,
  },
  props: {
    program: {
      type: Object as () => Program,
      required: true,
    },
    rewardResponse: {
      type: Object as () => RewardTrackerResponse | null,
      required: false,
    },
    requiredActivitiesNotCompletedCount: {
      type: Number,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
    companyLogo: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      showPanel: false,
      UpChevron,
      RightChevron,
      smallImage,
      largeImage,
    };
  },
  methods: {
 togglePanel() {
      this.showPanel = !this.showPanel;
  },
  },


});
</script>

<style lang="sass" scoped>
.program-container
  max-width: 1158px
  min-width: 300px
  border-radius: 16px
  background: rgba(255, 255, 255, 0.71)
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10)
  margin-left: 13rem
  padding-bottom: 2rem
  display: flex
  flex-direction: row

.program-content-container
  display: flex
  max-width: 650px
  min-width: 300px
  padding-left: 70px
  flex-direction: column
  align-items: flex-start

.program-name
  margin-bottom: 0

.program-description
  font-size: 16px
  line-height: 24px
  color: $color-primary-hc-blue-100
  @include Roboto400
  max-width: 600px

.company-logo-container
  display: flex
  flex-direction: column
  align-items: center
  margin-left: 5rem
  margin-right: 2rem
  margin-top: 2rem

.program-button
  text-decoration: none
  display: flex
  width: 218px
  height: 45px
  padding: 12px 0px
  margin-top: 40px
  justify-content: center
  align-items: center
  gap: 8px
  flex-shrink: 0
  background-color: $color-primary-hc-blue-100
  color: white
  border: none
  border-radius: 40px
  cursor: pointer
  font-size: 18px
  letter-spacing: 0.5px
  @include Asap600

.rewards-container
  display: flex
  max-width: 580px
  min-width: 300px
  padding-top: 20px
  flex-direction: column
  align-items: flex-start

.rewards-warning
  display: flex
  margin-top: 20px
  flex-direction: column
  color: $color-status-error-red

.rewards-warning-icon
  height: 20px
  width: 20px

.rewards-warning-content
  display: flex
  align-items: center
  gap: 6px
  font-size: 14px
  line-height: 22px
  letter-spacing: 0.2px
  @include Roboto400

.tooltip-icon
  width: 27px
  height: 27px
  cursor: pointer

@media (max-width: 1500px)
  .program-container
    flex-direction: column
    margin-left: 1rem
    margin-right: 1rem
    padding: 1rem
    justify-self: center

  .program-content-container
    padding-left: 1rem
    padding-right: 1rem

@media (max-width: 1200px)
  .program-container
    margin-left: 0.5rem
    margin-right: 0.5rem
    padding: 0.5rem

  .program-content-container
    padding-left: 0.5rem
    padding-right: 0.5rem

@media (max-width: 900px)
  .program-container
    margin-left: 0.25rem
    margin-right: 0.25rem
    padding: 0.25rem

  .program-content-container
    padding-left: 0.25rem
    padding-right: 0.25rem

@media (max-width: 600px)
  .program-container
    margin-left: 0
    margin-right: 0
    padding: 0

  .program-content-container
    padding-left: 0
    padding-right: 0
</style>
