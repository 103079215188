<template>
  <div class="view-all-data">
    <div class="month-picker">
      <img
        src="@/assets/img/left-arrow-cal.svg"
        alt="left"
        class="navigation"
        @click="backward"
      />
      <div class="selected-month">
        {{getMonthYear(selectedMonth)}}
      </div>
      <img
        src="@/assets/img/right-arrow-cal.svg"
        alt="right"
        class="navigation"
        @click="forward"
      />
    </div>
    <div v-if="loading" class="spinner"/>
    <div  v-else>
    <ul class="days">
      <li
        class="day"
        v-for="(day, index) in Object.keys(trackingMetrics)"
        :key="index"
      >
        <div class="day-label">{{day}}</div>
        <div class="day-value">{{ trackingMetrics[day] }}
          <span>{{ unit }}</span>
        </div>
      </li>
    </ul></div>
  </div>
</template>

<script lang="ts" setup>
import {inject, onMounted, ref} from "vue";
import {Api} from "@/ts/classes/api";
import {
  addToDate,
  convertDateToServerFormat,
  getDaysInMonth,
  getEndOfTheMonth,
  getMonth,
  getMonthYear,
  getStartOfTheMonth,
} from "@/ts/utils/date-pure-functions";
import {TaskType} from "@/ts/types/dto/activity.dto";
import {incrementMonthBy} from "@/ts/utils/datepicker-helpers";
import {groupBy} from "@/ts/utils/pure-functions";

const props = defineProps<{
  taskTypes: TaskType[];
  unit: string;
}>();
const $api = inject("$api")! as Api;
interface DateToTrackedAmount {
  [key: string]: number;
}
const trackingMetrics = ref<DateToTrackedAmount>({})
const selectedMonth = ref(new Date())
const loading = ref(true);

onMounted(async () => {
  try {
    await fetchData()
  } catch (err) {
    console.error(err)
  }
})

const fetchData = async () => {
  loading.value = true; 

  try {
    trackingMetrics.value = {}

    const startDate = getStartOfTheMonth(selectedMonth.value)
    const endDate = getEndOfTheMonth(selectedMonth.value)
    const resp = await $api.getTrackingList({
      startDate: convertDateToServerFormat(startDate),
      endDate: convertDateToServerFormat(endDate),
      taskType: props.taskTypes.join(",")
    })

    const grouped = groupBy(resp, (item) => item.date);
    const daysInMonth = getDaysInMonth(selectedMonth.value)

    for (let day = 0; day < daysInMonth ; day++) {
      const date = addToDate(startDate, {days: day})
      const dateStr = convertDateToServerFormat(date)
      let trackedAmount = 0
      if (!!grouped[dateStr]) {
        trackedAmount = grouped[dateStr].map(item => item.trackedAmount).reduce((prev: number, curr: any) => prev + curr, 0)
      }
      trackingMetrics.value[`${getMonth(date)} ${date.getDate()}`] = props.taskTypes.includes(TaskType.steps) ? trackedAmount : Math.floor(trackedAmount/60) // ugly
      loading.value = false; 

    }

  } catch (err) {
    console.error(err)
    loading.value = false; 

  }
}
defineExpose({fetchData});

const backward = () => {
  selectedMonth.value = incrementMonthBy(-1, selectedMonth.value)
  fetchData()
}

const forward = () => {
  selectedMonth.value = incrementMonthBy(1, selectedMonth.value)
  fetchData()
}
</script>

<style lang="sass" scoped>
.view-all-data
  padding: 24px 16px
  box-shadow: 0 0 10px rgba(15, 38, 78, 0.15)
  border-radius: 10px
  background-color: $color-white

.month-picker
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 25px

.days
  list-style: none
  padding: 0
  margin-bottom: 0

.day
  @include Asap400
  margin: 16px 0
  display: grid
  grid-template-columns: 150px auto
  justify-content: space-between

  &:last-child
    margin-bottom: 0

  span
    color: $color-primary-hc-blue-50

.navigation
  cursor: pointer

  
.spinner
  @include lds-spinner(20px, "Loading", false, $color-secondary-state-blue-100)
  margin-top: 32px
</style>
