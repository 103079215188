<template>
  <div class="group-parent">
    <div class="rectangle-parent" :style="{backgroundColor: backgroundColor}">
      <div class="group-child"/>
      <b class="my-activity">{{ title }}</b>
      <div class="rectangle-group">
        <div class="group-item"/>
        <div class="value">
          <span>{{ value }} </span>
          <span class="unit">{{ unit }}</span>
        </div>
      </div>
      <img class="group-inner" alt="" :src="personImage"/>
      <img class="chevron" alt="chevron" src="@/assets/img/right-arrow.svg"/>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, computed} from "vue";
import mySteps from "@/assets/img/dashboard/my-steps.svg";
import myActivity from "@/assets/img/dashboard/my-activity.svg";
import mySleep from "@/assets/img/dashboard/my-sleep.svg";

function getTitle(type: string): string {
  switch (type) {
    case "steps":
      return "My Steps";
    case "activity":
      return "My Activity";
    case "sleep":
      return "My Sleep";
    default:
      return "";
  }
}

function getUnit(type: string): string {
  switch (type) {
    case "steps":
      return "";
    case "activity":
      return "minutes";
    case "sleep":
      return "hours";
    default:
      return "";
  }
}

function getBackgroundColor(type: string): string {
  switch (type) {
    case "steps":
      return "#fdeff9";
    case "activity":
      return "#fff8e9";
    case "sleep":
      return "rgba(244, 251, 249, 0.6)";
    default:
      return "";
  }
}

function getPersonImage(type: string): string {
  switch (type) {
    case "steps":
      return mySteps;
    case "activity":
      return myActivity;
    case "sleep":
      return mySleep;
    default:
      return "";
  }
}

export default defineComponent({
  name: "ProgressItem",
  props: {
    type: {
      type: String,
      required: true,
      validator: (value: string) => ["steps", "activity", "sleep"].includes(value),
    },
    value: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const title = computed(() => getTitle(props.type));
    const unit = computed(() => getUnit(props.type));
    const backgroundColor = computed(() => getBackgroundColor(props.type));
    const personImage = computed(() => getPersonImage(props.type));

    return {
      title,
      unit,
      backgroundColor,
      personImage,
    };
  },
});
</script>

<style lang="sass" scoped>
.group-child
  position: absolute
  bottom: 0
  right: 0
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05)
  border-radius: 16px
  background-size: cover
  background-repeat: no-repeat

.my-activity
  position: absolute
  top: 5px
  left: calc(50% - 90.03px)
  line-height: 28px
  display: flex
  align-items: flex-end
  width: 140.8px

.group-item
  position: absolute
  top: 0px
  left: 0px
  border-radius: 16px
  background-color: #fff
  width: 100%
  height: 40px

.unit
  font-size: 16px
  padding-left: 5px

.value
  position: absolute
  top: 9px
  left: 10.06px
  letter-spacing: 0.2px
  font-weight: 400
  line-height: 22px
  display: inline-block
  width: 138.8px

.rectangle-group
  position: absolute
  top: 37px
  left: 17.1px
  width: 148.9px
  height: 40px
  font-size: 32px
  font-family: Roboto

.rectangle-parent
  position: absolute
  top: 0px
  left: 0px
  width: 220.3px
  height: 131px
  border-radius: 16px
  transition: box-shadow 0.3s ease
  cursor: pointer
  &:hover
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2)
    .chevron
      opacity: 1

.group-inner
  position: absolute
  bottom: 0
  right: 0
  width: auto
  height: auto

.group-parent
  width: 100%
  position: relative
  height: 131px
  text-align: left
  font-size: 20px
  color: #3C5775
  font-family: Asap

.chevron
  position: absolute
  top: 10px
  right: 10px
  width: 20px
  height: 20px
  opacity: 0
  transition: opacity 0.3s ease
</style>