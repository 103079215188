<template>
  <div>
    <popup-modal :menu="menu" :model-value="modelValue" @update:model-value="close">
      <div class="wrapper" @click="isOpen = true">
        <p class="title">
          {{ title }}
        </p>
        <loading-suspense :loading="loading" :error="error">
          <div class="list">
            <task-card v-if="menu === 'activities' || menu === 'health'"
                v-for="(item,index) in taskTypeArray.value"
                :key="item"
                :type="item"
                :model-value="modelValue"
                @update:model-value="close"
                @tracking-updated="trackingUpdated"
                :is-highlighted="isTracked(item)"
                :amount-value="amountValue(index)"/>
            <task-card v-else
                v-for="i in items"
                :key="i"
                :type="i"
                :model-value="modelValue"
                @update:model-value="close"
                @tracking-updated="trackingUpdated"
            />
          </div>
        </loading-suspense>
      </div>
    </popup-modal>
  </div>
</template>

<script lang="ts">
import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import PopupModal from "@/vue/atoms/popup-modal.vue";
import TaskCard from "@/vue/organisms/tracking/task-card.vue";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import {mixins} from "vue-class-component";
import {
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import {TaskTypeActivities, TaskTypeHealth, TaskTypeMood, TaskTypeNutrition} from "@/ts/types/component/tracking-list";
import {ref} from "vue";
import { indexOf } from "lodash";

@Component({
  name: "TrackActivityModal",
  components: {
    PopupModal,
    TaskCard,
    LoadingSuspense,
  },
})

export default class ListTrackActivityModal extends mixins(LoadingMixin) {
  isOpen: boolean = false;

  @Prop() modelValue!: boolean;

  @Prop() menu!: string;
  
  @Prop() trackedActivitiesArray !: [string, number][];
  @Prop() showNicotineEmpty!: boolean;

  taskTypeArray = ref<string[]>([]);

  trackedAmountArray = ref<number[]>([]);

  get title(): string {
    switch (this.menu) {
      case "health":
        return "Health";
      case "activities":
        return "Activities";
      case "mood":
        return "Mood";
      case "nutrition":
        return "Nutrition";
      default:
        return " ";
    }
  }

  get items(): string[] {
    switch (this.menu) {
      case "health":
        return Object.keys(TaskTypeHealth);
      case "activities":
        return Object.keys(TaskTypeActivities);
      case "mood":
        return Object.keys(TaskTypeMood);
      case "nutrition":
        return Object.keys(TaskTypeNutrition);
      default:
        return [];
    }
  }

  @Emit("update:modelValue")
  close(): boolean {
    return false;
  }

  @Emit("tracking-updated")
  trackingUpdated(): void {
    console.log("Event emitted from list-track-activity-modal: tracking-updated");
  }

  @Watch("trackedActivitiesArray.value", {immediate: true, deep: true})
  onTrackedActivitiesArrayChange(newVal: [string, number][]) {
    if (Array.isArray(newVal)) {
      this.taskTypeArray.value = newVal.map((item) => item[0]);
      this.trackedAmountArray.value = newVal.map((item) => item[1]);
      console.log("@Watch event triggered in list-track-activity-modal");
    }
  }

  isTracked(taskType:string): boolean {
    let val = this.amountValue(indexOf(this.taskTypeArray.value, taskType));
    return  val != 0 || ( val == 0 && taskType == 'nicotine_use' && this.showNicotineEmpty==false);
  }

  amountValue(index:number): number {
    const value = this.trackedAmountArray.value[index];
    if (value != null && value>0){
      return value;
    }
    return 0;
  }
}
</script>

<style lang="sass" scoped>
.title
  @include Asap700
  font-size: 32px
  color: $color-primary-hc-blue-100

.list
  display: flex
  overflow-y: auto
  max-width: 800px
  flex-wrap: wrap
  max-height: 500px

::v-deep .content
  margin: 10rem
</style>
