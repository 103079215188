<template>
  <div class="container">
    <div class="header">
      <div class="title">
        <img alt="gatekeeper" src="@/assets/img/icons/gatekeeper_blue.svg"/>
        <h4>Required Actions</h4>
      </div>
      <span v-if="showOnlyPendingItems" class="counter">{{ counterLabel }}</span>
    </div>
    <p class="description">
      {{ defaultDescription }}
    </p>
    <ul class="items">
      <li v-for="item in filteredItems" :key="item.id">
        <button :class="['item', { 'success': item.status == 'completed'}]"  @click="onClickItem(item)">
          <squared-icon v-if="item.status == 'completed'" class="icon" icon-type="successWhite" />
          <squared-icon v-else class="icon" icon-type="gatekeeper"/>
          <label class="label">
            <span class="name">{{ item.name }}</span>
            <span v-if="item.completedDate" class="end-date">{{ getCompletedDate(item.completedDate) }}</span>
            <span v-else class="end-date">{{ getEndDate(item.endDate) }}</span>
          </label>
        </button>
      </li>
    </ul>
  </div>

  <gatekeeper-modal
    :gatekeeper-item="selectedGatekeeperItem"
    :is-open="isGatekeeperModalOpen"
    @update:isOpen="closeModal"
  />
</template>

<script lang="ts" setup>
import type {GatekeeperItem} from "@/ts/types/dto/program.dto";
import {GatekeeperStatus} from "@/ts/types/dto/program.dto";
import type {IconType} from "@/ts/types/component/transaction-history";
import {ContentReferenceType} from "@/ts/types/dto/content-reference.dto";
import {formatDateShortMonthName} from "@/ts/utils/date-pure-functions";
import {computed, inject, ref} from "vue";
import GatekeeperModal from "@/vue/molecules/gatekeeper-modal.vue";
import type {Router} from "vue-router";
import type {PagesPath} from "@/ts/router/pages-path";
import SquaredIcon from "@/vue/atoms/squared-icon.vue";

const props = defineProps<{
  defaultDescription?: string;
  items: GatekeeperItem[];
  showOnlyPendingItems?: boolean;
}>();

const $router = inject("$router")! as Router;
const $pagesPath = inject<PagesPath>("$pagesPath")!;

const isGatekeeperModalOpen = ref(false);
const selectedGatekeeperItem = ref<GatekeeperItem | null>(null);

const getIconType = (item: GatekeeperItem): IconType => {
  switch (item.contentReference.type) {
    case ContentReferenceType.Activity:
      return item.taskType as IconType;
    case ContentReferenceType.BiometricScreening:
      return "bio"; // TODO refactor this as an enum
    case ContentReferenceType.HealthRiskAssessment:
      return "hra";
    case ContentReferenceType.Coaching:
      return "coaching";
    default:
      return "generic";
  }
};

const getEndDate = (endDate: string | undefined) => (endDate ? `End date: ${formatDateShortMonthName(endDate)}` : "");

const getCompletedDate = (completedDate: string | undefined) => (completedDate ? `Completed on: ${formatDateShortMonthName(completedDate)}` : "");

const filteredItems = computed<GatekeeperItem[]>(() => {
  if (props.showOnlyPendingItems) {
    // Filter pendingItems further to include only those that are required.
    return pendingItems.value.filter((item) => item.isRequired);
  }
    // Filter props.items to include only those that are required.
    return props.items.filter((item) => item.isRequired);
});


const pendingItems = computed<GatekeeperItem[]>(() => props.items.filter((i) => i.status === GatekeeperStatus.pending));

const completedItems = computed<GatekeeperItem[]>(() => props.items.filter((i) => i.status === GatekeeperStatus.completed));

const counterLabel = computed(() => completedItems.value.length === props.items.length
  ? "completed"
  : `${pendingItems.value.length} remaining`);

const onClickItem = (item: GatekeeperItem) => {
  switch (item.contentReference.type) {
    case ContentReferenceType.Activity:
      selectedGatekeeperItem.value = item;
      isGatekeeperModalOpen.value = true;
      return;
    case ContentReferenceType.BiometricScreening:
      $router.push($pagesPath.myHealthPath.myhealth);
      return;
    case ContentReferenceType.HealthRiskAssessment:
      $router.push($pagesPath.myHealthPath.myhealth);
      return;
    case ContentReferenceType.Coaching:
      $router.push($pagesPath.coaching.info);

    default:
  }
};

const closeModal = () => {
  isGatekeeperModalOpen.value = false;
};
</script>

<style lang="sass" scoped>
.header
  display: flex
  align-items: center
  justify-content: space-between

.title
  display: flex
  gap: 8px
  align-items: center

  > img
    width: 24px
    height: 24px

  > h4
    @include Asap700
    font-size: 16px
    text-transform: capitalize
    margin: 0

.counter
  color: $color-primary-hc-blue-50
  @include Roboto400

.description
  @include Roboto400
  font-size: 14px
  letter-spacing: 0.2px
  line-height: 22px
  margin-top: 8px

.items
  display: flex
  flex-direction: column
  gap: 16px
  padding: 0
  margin: 0
  list-style: none

.item
  background-color: $color-white
  border-radius: 16px
  display: flex
  padding: 12px
  width: 100%
  border: none
  color: inherit
  cursor: pointer
  &.success
    background-color: $color-status-success-green-10


  .icon
    width: 48px
    height: 48px

    &.activity
      color: $color-tertiary-turquoise-100

  .label
    display: flex
    flex-direction: column
    justify-content: space-between
    margin-left: 10px
    align-items: baseline
    height: 42px
    cursor: inherit

  .name
    grid-row: 1
    grid-column: 2
    @include Asap700
    color: $color-primary-hc-blue-100
    font-size: 16px
    line-height: 22px
    margin: 0

  .end-date
    grid-row: 2
    grid-column: 2
    @include Roboto400
    color: $color-primary-hc-blue-50
    font-size: 12px
    line-height: 16px
    margin: 0
</style>
