<template>
  <svg
    width="300"
    height="300"
    viewBox="0 0 300 300"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <rect
      x="0"
      y="0"
      width="300"
      height="300"
      rx="16"
      ry="16"
      :fill="`url(#${uniqueId}-pattern)`"
    />
    <defs>
      <pattern
        :id="`${uniqueId}-pattern`"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use :xlink:href="`#${uniqueId}`" :transform="transform"/>
      </pattern>
      <image
        :id="uniqueId"
        :xlink:href="imageObjectUrl"
        width="1"
        height="1"
        preserveAspectRatio="xMidYMid meet"
      />
    </defs>
  </svg>
</template>

<script lang="ts" setup>
import {computed, inject, onMounted, onUnmounted, ref, watch} from "vue";
import type {Api} from "@/ts/classes/api";

const $api: Api = inject("$api")!;

const imageObjectUrl = ref("");

const props = defineProps<{
  src: string;
}>();

const transform = computed((): string => {
  return `translate(0 0) scale(1 1)`;
});

const uniqueId = computed((): string => {
  return `image-${Date.now()}`;
});

const revokeImageData = (): void => {
  if (imageObjectUrl.value) {
    URL.revokeObjectURL(imageObjectUrl.value);
    imageObjectUrl.value = "";
  }
};

const fetchImage = async (): Promise<void> => {
  if (props.src) {
    try {
      const image = await $api.getProtectedImage(props.src);
      imageObjectUrl.value = image.imageObjectUrl;
    } catch (err) {
      // don't display image
    }
  }
};

onMounted(() => {
  void fetchImage();
});

onUnmounted(() => {
  revokeImageData();
});

watch(() => props.src, () => {
  void fetchImage();
});
</script>