<template>
  <div class="progress-container">
    <div class="progress-content-container">
      <progress-item type="steps" :value="stepsValue" @click="navigateToPath('steps')" />
      <progress-item type="activity" :value="activityValue" @click="navigateToPath('activity')" />
      <progress-item type="sleep" :value="sleepValue" @click="navigateToPath('sleep')" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ProgressItem from "@/vue/molecules/dashboard/progress-item.vue";
import { pagesPath } from "@/ts/router/pages-path";

export default defineComponent({
  name: "ProgressSection",
  components: {
    ProgressItem,
  },
  props: {
    stepsValue: {
      type: Number,
      required: true,
    },
    activityValue: {
      type: Number,
      required: true,
    },
    sleepValue: {
      type: Number,
      required: true,
    },
  },
  methods: {
    navigateToPath(type: string) {
      switch (type) {
        case 'steps':
          this.$router.push(pagesPath.profile.myStepsProgress);
          break;
        case 'activity':
          this.$router.push(pagesPath.profile.myMinutesOfActivityProgress);
          break;
        case 'sleep':
          this.$router.push(pagesPath.profile.myProgress); 
          break;
        default:
          this.$router.push(pagesPath.profile.myPath);
      }
    },
  },
});
</script>

<style lang="sass" scoped>
.progress-container
  display: flex
  flex-direction: column
  align-items: flex-start
  margin-left: 2rem
  margin-top: 4rem

.progress-content-container
  display: flex
  flex-direction: column
  gap: 2rem

@media (max-width: 1500px)
  .progress-container
    margin-left: 1rem
    margin-right: 1rem
    display: block

  .progress-content-container
    gap: 1rem
    flex-direction: row
    margin-bottom: 4rem

@media (max-width: 1200px)
  .progress-container
    margin-left: 0.5rem
    margin-right: 0.5rem

  .progress-content-container
    gap: 0.5rem
    flex-direction: row
    margin-bottom: 4rem

@media (max-width: 900px)
  .progress-container
    margin-left: 0.25rem
    margin-right: 0.25rem

  .progress-content-container
    gap: 2rem
    flex-direction: row
    margin-bottom: 4rem

@media (max-width: 600px)
  .progress-container
    margin-left: 0
    margin-right: 0

  .progress-content-container
    flex-direction: column
    justify-self: center
    margin-right: 10rem
</style>
