<template>
  <div class="wrapper">
    <div class="icon-container">
      <img :src="icon" class="icon" alt="notification icon" />
    </div>
    <div class="background-icon">
      <svg
        width="100%"
        height="100%"
        :viewBox="`0 0 350 350`"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M175 0C35 0 0 34.9986 0 175C0 315.001 34.9986 350 175 350C315.001 350 350 315.001 350 175C350 34.9986 315.001 0 175 0Z"
          fill="currentColor"
        />
      </svg>
    </div>
  </div>
</template>
<script lang="ts" setup>
import {computed} from "vue";
import {NotificationTarget} from "@/ts/types/dto/notification.dto";
import key from "@/assets/img/notification-icons/key.svg"
import calendar from "@/assets/img/notification-icons/calendar.svg"
import cross from "@/assets/img/notification-icons/cross.svg"
import bell from "@/assets/img/notification-icons/bell.svg"
import checkmark from "@/assets/img/notification-icons/checkmark.svg"
import warning from "@/assets/img/notification-icons/warning.svg"

const props = defineProps<{
  notificationTarget: NotificationTarget;
}>();

const icon = computed((): string => {
  const NotificationIconMap = {
    [NotificationTarget.program_end_soon]: warning,
    [NotificationTarget.program_end_today]: warning,
    [NotificationTarget.program_start_today]: calendar,
    [NotificationTarget.level_change]: checkmark,
    [NotificationTarget.activity_starts_today]: bell,
    [NotificationTarget.activity_gatekeeper_due]: key,
    [NotificationTarget.challenge_win]: checkmark,
    [NotificationTarget.challenge_lose]: cross,
    [NotificationTarget.activity_proof_accept]: checkmark,
    [NotificationTarget.activity_proof_decline]: cross,
    [NotificationTarget.cash_request_accept]: checkmark,
    [NotificationTarget.fitbit_access_revoked]: bell,
    [NotificationTarget.fitbit_account_deleted]: bell,
    [NotificationTarget.activity_end_soon]: bell,
    [NotificationTarget.health_tracker_disconnected]: warning,
  };
  return NotificationIconMap[props.notificationTarget] || bell;
});
</script>

<style lang="sass" scoped>
.wrapper
  position: relative
  width: 48px
  height: 48px

.background-icon
  width: 100%
  height: 100%

.icon-container
  width: inherit
  height: inherit
  position: absolute
  display: flex
  justify-content: center

.icon
  max-width: 60%
</style>
