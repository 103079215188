<template>
  <div class="todo-container">
    <div class="todo-content-container">
      <h1 class="todo-title">
        My To-Do's
        <tooltip-wrapper tooltip-text="The To Do list is a way for you to prioritize required or recommended activities outlined by your company and customize your goals by including activities that are important to you.">
          <img src="@/assets/img/tooltip-question.svg" alt="tooltip" class="tooltip-icon"/>
        </tooltip-wrapper>
      </h1>
      <calendar-list v-model="localCurrentDay" @update:modelValue="updateCurrentDay"/>
      <todo-list :day="localCurrentDay" @todos-updated="emitTodosUpdated"/>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CalendarList from "@/vue/organisms/calendar-list.vue";
import TodoList from "@/vue/organisms/todo-list.vue";
import TooltipWrapper from "@/vue/atoms/tooltip-wrapper.vue";

export default defineComponent({
  name: "TodoSection",
  components: {
    CalendarList,
    TodoList,
    TooltipWrapper,
  },
  props: {
    currentDay: {
      type: Date,
      required: true,
    },
  },
  data() {
    return {
      localCurrentDay: this.currentDay,
    };
  },
  watch: {
    currentDay(newDay) {
      this.localCurrentDay = newDay;
    },
  },
  methods: {
    updateCurrentDay(newDay: Date) {
      this.localCurrentDay = newDay;
      this.$emit("update:currentDay", newDay);
    },
    emitTodosUpdated() {
      this.$emit('todos-updated');
    },
  },
});
</script>

<style lang="sass" scoped>
.todo-container
  display: flex
  justify-content: flex-start
  margin-left: 13rem
  margin-top: 4rem

.todo-content-container
  display: flex
  max-width: 913px
  min-width: 300px
  padding: 24px
  flex-direction: column
  align-items: flex-start
  flex-shrink: 0
  border-radius: 16px
  background: rgba(255, 255, 255, 0.75)
  box-shadow: 0px 13px 40px 0px rgba(0, 0, 0, 0.05)
  margin-bottom: 50px

.todo-title
  margin-left: 2rem

.tooltip-icon
  width: 27px
  height: 27px
  cursor: pointer

@media (max-width: 1500px)
  .todo-container
    margin-left: 1rem
    margin-right: 1rem
    justify-content: center

  .todo-content-container
    padding: 16px
    margin-bottom: 20px
    max-width: 800px

@media (max-width: 1200px)
  .todo-container
    margin-left: 0.5rem
    margin-right: 0.5rem

  .todo-content-container
    padding: 12px
    margin-bottom: 15px
    max-width: 700px

@media (max-width: 900px)
  .todo-container
    margin-left: 0.25rem
    margin-right: 0.25rem

  .todo-content-container
    padding: 8px
    margin-bottom: 10px
    max-width: 600px

@media (max-width: 600px)
  .todo-container
    margin-left: 0
    margin-right: 0

  .todo-content-container
    padding: 4px
    margin-bottom: 5px
    max-width: 500px

@media (max-width: 400px)
  .todo-content-container
    padding: 2px
    margin-bottom: 2px
    max-width: 200px

</style>
