<template>
  <div :class="className">
    <slot/>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";

@Component({
  name: "CardWrapper",
})
export default class CardWrapper extends Vue {
  @Prop({default: "large"}) size!: "large" | "small";

  @Prop({default: "none"}) border!: "none" | "orange";

  get className(): string {
    return `card ${this.size} ${this.border}`;
  }
}
</script>

<style lang="sass" scoped>
.card
  width: 200px
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15)
  background-color: $color-white
  border-radius: 16px
  margin: 8px 16px 8px 0
  display: flex
  flex-direction: column
  padding: 16px
  justify-content: space-between

.orange
  border: 1.5px solid $color-tertiary-orange-100

.large
  height: 239px


.small
  height: 213px
</style>
