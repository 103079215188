<template>
  <div>
    <div v-if="!showPanel">
      <span class="text-container" @click="togglePanel">
        <span v-html="sanitizedTruncatedText"/>
        <div class="see-more">see more
          <icon-chameleon
            v-if="text.length > truncationLength"
            class="chevron"
            color="primary100"
            hover-color="primary100"
            :icon="showPanel ? UpChevron : RightChevron"
          />
        </div>
      </span>
    </div>

    <expansion-panel :expanded="showPanel">
      <template #body>
        <span class="text-container" @click="togglePanel">
          <span v-html="sanitizedFormattedText"/>
          <div class="see-more">see less
            <icon-chameleon
              v-if="text.length > truncationLength"
              class="chevron"
              color="primary100"
              hover-color="primary100"
              :icon="showPanel ? UpChevron : RightChevron"
            />
          </div>
        </span>
      </template>
    </expansion-panel>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref, computed} from "vue";
import MD from "markdown-it";
import DOMPurify from "dompurify";
import ExpansionPanel from "@/vue/atoms/expansion-panel.vue";
import IconChameleon from "@/vue/atoms/icon-chameleon.vue";
import RightChevron from "@/assets/img/right-chevron.svg";
import UpChevron from "@/assets/img/up-chevron.svg";

export default defineComponent({
  name: "ExpandableMarkdownIt",
  components: {
    ExpansionPanel,
    IconChameleon,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    truncationLength: {
      type: Number,
      default: 250,
    },
  },
  // eslint-disable-next-line max-lines-per-function
  setup(props) {
    const showPanel = ref(false);

    const sanitizedFormattedText = computed(() => {
      const renderedMarkdown = new MD().render(props.text);
      return sanitize(renderedMarkdown);
    });

    const sanitizedTruncatedText = computed(() => {
        const renderedMarkdown = new MD().render(props.text);
        const truncatedHtml = truncateHTML(renderedMarkdown, props.truncationLength);
        return sanitize(truncatedHtml);
    });

    // Utility function to truncate HTML while preserving tags
    const truncateHTML = (html: string, maxLength: number): string => {
        let length = 0;
        const stack: string[] = [];
        let isTruncated = false;

        let truncated = html.replace(/(<[^>]+>|[^<]+|>)/g, (match) => {
            if (length >= maxLength) {
                isTruncated = true;
                return "";
            }
            if ((/<[^>]+>/).test(match)) {
                // eslint-disable-next-line no-negated-condition
                if (!(/^<\/.+>/).test(match)) {
                    stack.push(((/^<(\w+)/).exec(match))?.[1] || "");
                } else {
                    stack.pop();
                }
                return match;
            }
            const remaining = maxLength - length;
            const truncatedText = match.substring(0, remaining);
            length += truncatedText.length;
            return truncatedText;
        });

        if (isTruncated) {
            truncated += "<span>...</span>";
        }

        while (stack.length) {
            const tag = stack.pop();
            if (tag) {
                truncated += `</${tag}>`;
            }
        }

        return truncated;
    };

    const sanitize = (text: string): string => {
        return DOMPurify.sanitize(text, {
            ALLOWED_TAGS: [
                "a",
                "b",
                "i",
                "em",
                "strong",
                "span",
                "p",
                "br",
                "h1",
                "h2",
                "h3",
                "h4",
                "h5",
                "h6",
                "ul",
                "ol",
                "li",
                "s",
                "img",
            ],
            ALLOWED_ATTR: ["href", "src", "alt", "title"],
        });
    };

    const togglePanel = () => {
      showPanel.value = !showPanel.value;
    };

    return {
      showPanel,
      sanitizedFormattedText,
      sanitizedTruncatedText,
      togglePanel,
      UpChevron,
      RightChevron,
    };
  },
});
</script>

<style lang="sass" scoped>
.text-container
  display: inline

.chevron
  font-weight: bold
  font-size: 10px
  cursor: pointer
  vertical-align: middle

.see-more
  font-weight: bold
  cursor: pointer
  margin-top: -1rem

</style>
