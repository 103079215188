<template>
  <profile-field
    name="My Interests"
    description=""
    :error="error"
    :loading="loading"
    :show-success="showSuccess"
    :is-enabled="true"
    @save="save"
  >
    <interest-list
      :edit-mode="true"
      :interests="interestOptions"
      :selected-interests="selectedInterests"
      :float-left="true"
      @select-interest="onSelectInterest"
    />
  </profile-field>
</template>

<script lang="ts" setup>
import ProfileField from "@/vue/organisms/profile/profile-field.vue";
import type {Profile} from "@/ts/types/store/default-store-types";
import type {PutProfileRequestDTO, UserInterestDTO} from "@/ts/types/dto/profile.dto";
import {profileToPutProfileRequest} from "@/ts/utils/pure-functions";
import InterestList from "@/vue/molecules/interest-list.vue";
import {computed, inject, ref} from "vue";
import type {Api} from "@/ts/classes/api";
import type {CachedApi} from "@/ts/classes/cached-api";
import type {Interest} from "@/ts/types/store/onboarding-store-types";
import useStoreModule from "@/ts/composables/use-store-module";

const {defaultStore} = useStoreModule();
const $api: Api = inject("$api")!;
const $cachedApi: CachedApi = inject("$cachedApi")!;
const showSuccess = ref<boolean>(false);
const loading = ref<boolean>(false);
const error = ref<string>("");

const selectedInterests = ref<Interest[]>((defaultStore.profile?.interests ?? []).map((item) => {
  return {
    id: item.id,
    name: item.name,
  };
}));

const interestOptions = computed((): UserInterestDTO[] => {
  return defaultStore.userInterestOptions ?? [];
});

const save = async(): Promise<void> => {
  loading.value = true;
  showSuccess.value = false;
  try {
    const value: PutProfileRequestDTO = {
      ...profileToPutProfileRequest(defaultStore.profile!),
      interests: selectedInterests.value,
    };
    const getProfileResponseDTO = await $api.updateProfile(value);
    const profileData: Profile = {
      ...getProfileResponseDTO,
      dateOfBirth: defaultStore.profile!.dateOfBirth,
      location: defaultStore.profile!.location,
      department: defaultStore.profile!.department,
      myCareEnabled: defaultStore.profile!.myCareEnabled,
    };
    defaultStore.setProfile(profileData);
    showSuccess.value = true;
  } catch (err: any) { // eslint-disable-line
    error.value = err.toString(); // eslint-disable-line
  } finally {
    loading.value = false;
  }
};

const onSelectInterest = (selectedInterest: UserInterestDTO): void => {
  const alreadySelectedIndex = selectedInterests.value.findIndex((item) => item.id === selectedInterest.id);
  if (alreadySelectedIndex === -1) {
    selectedInterests.value.push({
      id: selectedInterest.id,
      name: selectedInterest.name,
    });
  } else {
    selectedInterests.value.splice(alreadySelectedIndex, 1);
  }
};

void $cachedApi.meGetInterestOptions();

</script>
