<template>
  <div class="tracking-button">
    <Popper class="todo-dropdown" placement="top-start" offset-skid="-25px">
      <button class="contextual-button">
        <img src="@/assets/img/tracking/add-track.svg" alt="TrackActivity"/>
      </button>
      <template #content>
        <div class="dropdown-pane">
          <div @click="openModal('activities')">
            <img src="@/assets/img/tracking/track-activity.svg" alt="Activity"/>
          </div>
          <div @click="openModal('mood')">
            <img src="@/assets/img/tracking/track-mood.svg" alt="Mood"/>
          </div>
          <div @click="openModal('health')">
            <img src="@/assets/img/tracking/track-health.svg" alt="Health"/>
          </div>
          <div @click="openModal('nutrition')">
            <img
              src="@/assets/img/tracking/track-nutrition.svg"
              alt="Nutrition"
            />
          </div>
        </div>
      </template>
    </Popper>
    <list-track-activity-modal v-model="isOpen" :menu="menu" :tracked-activities-array="trackedActivitiesArray" @tracking-updated="handleTrackingUpdated" :show-nicotine-empty="showNicotineEmpty" />
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import Popper from "vue3-popper";
import ListTrackActivityModal from "@/vue/organisms/tracking/list-track-activity-modal.vue";
import {api} from "@/ts/instances/main-instances";
import {convertDateToServerFormat, getEndOfToday, getStartOfToday} from "@/ts/utils/date-pure-functions";
import {TaskTypeActivities, TaskTypeHealth} from "@/ts/types/component/tracking-list";
import {ref} from "vue";

@Component({
  name: "AddTrackingButton",
  components: {
    Popper,
    ListTrackActivityModal,
  },
})
export default class AddTrackingButton extends Vue {
  isOpen: boolean = false;

  menu: string = "";

  trackedActivitiesArray = ref<[string, number][]>([]);
   showNicotineEmpty = false;

  async openModal(menuType: string): Promise<void> {
    this.menu = menuType;
    this.isOpen = false;
    if ((menuType === "activities" || menuType === "health")) {
      await this.fetchTrackedChanges();
    }
    this.isOpen = true;
  }

  async fetchTrackedChanges(): Promise<void> {
    const trackedActivitiesMap: Map<string, number> = new Map<string, number>();
    let taskTypes: string[];
    if (this.menu === "activities") {
      taskTypes = Object.keys(TaskTypeActivities);
    } else {
      taskTypes = Object.keys(TaskTypeHealth);
    }

    const promises = taskTypes?.map(async(task) => {
      const response = await api?.getTrackingList({
        startDate: convertDateToServerFormat(getStartOfToday()),
        endDate: convertDateToServerFormat(getEndOfToday()),
        taskType: task,
      });
      return response;
    });
    // returns an array of arrays. Each individual array contains GetTrackingListResponseDTO
    const promiseArray = await Promise.all(promises);
    let nicotineTask = taskTypes.includes("nicotine_use");
    this.showNicotineEmpty = nicotineTask;
    // Process the promiseArray according to taskType and number of entries
    promiseArray.forEach((array) => {

      if (array.length !== 0 && taskTypes.includes(array[0].taskType)) {
        if(array[0].taskType == 'nicotine_use'){
          this.showNicotineEmpty = false;
        }
        if (array.length > 1) {
          // if menu = activities, add amounts from all entries
          if (this.menu === "activities" || array[0].taskType === "sleep_time" || array[0].taskType === "nicotine_use") {
            let amount = 0;
            array.forEach((dto) => {
              amount += dto.trackedAmount;
            });
            trackedActivitiesMap.set(array[0].taskType, amount);
          } else {
            trackedActivitiesMap.set(array[array.length -1].taskType, array[array.length -1].trackedAmount);
          }
        } else {
          trackedActivitiesMap.set(array[0].taskType, array[0].trackedAmount);
        }
        // remove tracked activities from taskTypes so we don't double display
        taskTypes = taskTypes.filter((taskType) => taskType !== array[0].taskType);
      }
    });

    taskTypes.forEach((taskType) => {
      trackedActivitiesMap.set(taskType, 0);
    });

    this.trackedActivitiesArray.value = Array.from(trackedActivitiesMap.entries());
  }

  handleTrackingUpdated(): void {
    this.isOpen = false;
    this.$emit('tracking-added');
    void this.fetchTrackedChanges();
  }
}
</script>

<style lang="sass" scoped>
.tracking-button
  position: fixed
  bottom: 0
  right: 50px

.todo-dropdown button
  background: none
  color: inherit
  border: none
  padding: 0
  font: inherit
  cursor: pointer
  outline: inherit

.dropdown-pane
  background-image: url("@/assets/img/tracking/tracking-options.svg")
  background-size: cover
  color: $color-white
  padding: 18px 0 0
  width: 351px
  height: 135px
  display: flex
  justify-content: space-around
  flex-direction: row

  > div
    width: 54px
    height: 56px
    display: flex
    cursor: pointer
    align-items: center
    flex-direction: column
    padding: 4px 0

    &:hover
      text-decoration: underline
</style>
