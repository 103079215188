<template>
  <loading-suspense :error="error" :loading="isLoading">
    <empty-state
      v-if="!program"
      :image="noProgramImage"
      title="There’s no program selected for you yet!"
      description="Please contact your admin."
    />
    <template v-else>
      <section class="main">
        <protected-image-curved-frame :src="coverPath" class="cover-url"/>
        <h2 class="title">
          <markdown-it :text="program.name"/>
        </h2>
        <program-details :program="program" :points-info="rewardTracker?.currentProgramPointsInfo"/>
      </section>
      <section class="progress">
        <reward-card :reward-response="rewardTracker" class="reward"/>
        <div v-if="showActionsContainer" class="actions-container">
          <gatekeeper-items
            v-if="gatekeeperItems.length && showRequired"
            default-description="In order to earn the incentive tied to your wellness program, you must complete the required activities."
            :items="gatekeeperItems"
          />
          <primary-action-card :gatekeeper-items="gatekeeperItems"/>
        </div>
      </section>
      <levels-section v-if="program?.levels?.length" :program="program"/>
    </template>
  </loading-suspense>
  <add-tracking-button/>
</template>
<script lang="ts" setup>
import type {MyProgramResponse, GatekeeperItem} from "@/ts/types/dto/program.dto";
import EmptyState from "@/vue/atoms/empty-state.vue";
import ProgramDetails from "@/vue/molecules/program-details.vue";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import RewardCard from "@/vue/molecules/reward-card.vue";
import LevelsSection from "@/vue/organisms/levels-section.vue";
import PrimaryActionCard from "@/vue/molecules/primary-action-card.vue";
import type {RewardTrackerResponse} from "@/ts/types/dto/reward-tracker";
import ProtectedImageCurvedFrame from "@/vue/atoms/protected-image-curved-frame.vue";
import noProgramImage from "@/assets/img/program-overview-empty-state.svg";
import AddTrackingButton from "@/vue/atoms/add-tracking-button.vue";
import GatekeeperItems from "@/vue/molecules/gatekeeper-items.vue";
import {computed, inject, onMounted, ref} from "vue";
import type {Api} from "@/ts/classes/api";
import type {CachedApi} from "@/ts/classes/cached-api";
import {HealthContentActivityResponseType} from "@/ts/types/dto/health.dto";
import MarkdownIt from "@/vue/atoms/markdown-it.vue";

const $api = inject("$api")! as Api;
const $cachedApi = inject("$cachedApi")! as CachedApi;
const isLoading = ref<boolean>(true);
const error = ref<string>("");
const program = ref<MyProgramResponse | null>(null);
const rewardTracker = ref<RewardTrackerResponse | null>(null);
const gatekeeperItems = ref<GatekeeperItem[]>([]);
const showRequired = ref<boolean>(false);

const showActionsContainer = computed(() => Boolean(
    program.value?.includeBiometricScreening ||
    program.value?.includeCoachingSessions ||
    program.value?.includeHra,
  ));

const emptyStateImage = () => noProgramImage;
const coverPath = computed(() => program.value?.coverPath!);

onMounted(async() => {
  try {
    program.value = await $cachedApi.getProgram();
    rewardTracker.value = await $cachedApi.getRewardTracker();
    gatekeeperItems.value = await $api.getGatekeeperItems(program.value.id, HealthContentActivityResponseType.HealthActivityProgramOverview);
    showRequired.value = gatekeeperItems.value.some((gi) => gi.isRequired);
  } catch (err) {
    error.value = err as string;
  } finally {
    isLoading.value = false;
  }
});
</script>

<style lang="sass" scoped>
.main
  max-width: 920px
  margin: 0 auto

.cover-url
  position: absolute
  top: 220px
  right: 0

  @include max-device(md)
    display: none

.title
  @include Asap700
  font-size: 28px
  line-height: 36px
  color: $color-primary-hc-blue-100
  text-align: center
  margin: 3rem auto

.progress
  max-width: 920px
  margin: 0 auto
  align-items: flex-start
  display: grid
  grid-template-columns: 1fr 1fr
  gap: 26px
  padding: 3rem 0

  @include max-device(md)
    flex-direction: column
    padding: 0 16px

.actions-container
  background-color: $color-primary-hc-blue-10
  display: flex
  gap: 26px
  flex-wrap: wrap
  padding: 24px
  border-radius: 16px

.coach
  display: flex
  flex-direction: column
  margin: 0 auto
  align-items: center
  margin-bottom: 40px

  p
    color: $color-primary-hc-blue-100
    @include Roboto400
    font-size: 14px
    line-height: 22px
    margin-bottom: 0

  button
    width: 339px
</style>
