<template>
  <div>
    <div class="nav-bar">
      <side-bar-menu/>
      <div class="title">
        <router-link v-if="backLink" :to="backLink" class="button-go-back">
          <img src="@/assets/img/left.svg" alt="Go back"/>
        </router-link>
        <reward-points v-if="rewardPoints" class="rewards-points"/>
        <h2 v-if="subtitle" :class="subtitleClass">
          {{ subtitle }}
        </h2>
        <h1 v-if="title">
          <markdown-it :text="title"/>
        </h1>
        <profile-and-notifications v-if="profileSection" class="notifications"/>
        <router-link v-if="closeLink" :to="$pagesPath.main.home" class="close-btn">
          <img
            src="@/assets/img/x-mark.svg"
            alt="close"
          />
        </router-link>
      </div>
    </div>
    <div :class="slotClass">
      <slot/>
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";
import SideBarMenu from "@/vue/organisms/side-bar-menu.vue";
import ProfileAndNotifications from "@/vue/molecules/profile-and-notifications.vue";
import RewardPoints from "@/vue/molecules/reward-points.vue";
import MarkdownIt from "@/vue/atoms/markdown-it.vue";

@Component({
  components: {RewardPoints, ProfileAndNotifications, SideBarMenu, MarkdownIt}
})
export default class NavbarPage extends Vue {
  @Prop()
  title!: string;

  @Prop()
  subtitle!: string;

  @Prop({default: ""})
  backLink!: string;

  @Prop({default: false})
  closeLink!: boolean;

  @Prop({default: false})
  profileSection!: boolean;

  @Prop({default: false})
  rewardPoints!: boolean;

  @Prop({default: "pink"}) theme!: "orange" | "pink";

  @Prop({default: ""}) slotCustom!: string;

  get subtitleClass(): string {
    return `subtitle ${this.theme}`;
  }

  get slotClass(): string {
    return `slot ${this.slotCustom}`;
  }
}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>
.custom
  flex: 1
  display: flex
  flex-direction: column

.nav-bar
  display: flex
  width: 100%
  justify-content: space-between

.title
  display: flex
  width: 100%
  align-items: center

.subtitle
  margin: auto
  text-align: center
  @include Roboto600
  font-size: 16px
  line-height: 24px
  letter-spacing: 1.5px
  text-transform: uppercase
  position: absolute
  width: 100%
  top: 12px

.pink
  color: $color-tertiary-pink-100

.orange
  color: $color-tertiary-orange-100

.close-btn
  position: absolute
  // head section should be centered, thus this element should not push it left
  right: 64px
  top: 72px
  cursor: pointer

h1
  margin: auto
  align-self: center
  @include Asap700
  font-size: 40px
  color: $color-primary-hc-blue-100

.rewards-points
  display: flex
  position: absolute
  // head section should be centered, thus this element should not push it right
  left: 200px
  align-items: center

.button-go-back
  display: flex
  position: absolute
  // head section should be centered, thus this element should not push it right
  left: 200px
  align-items: center

.notifications
  position: absolute
  // head section should be centered, thus this element should not push it left
  right: 0
  top: 20px

.slot
  transition: margin-left 0.1s ease-in-out

@media (min-width: 1800px)

  .button-go-back
    left: 310px

  .rewards-points
    left: 310px

</style>
