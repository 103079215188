<template>
  <navbar-page class="body" title="Steps" :back-link="$pagesPath.profile.myProgress">
    <div class="container">
      <div class="period-picker">
        <input-select
          v-model="selectedPeriod"
          :options="periodOptions"
        />
      </div>
      <view-all-data
        v-if="selectedPeriod === PeriodType.ALL_TIME"
        class="view-all"
        :task-types="[TaskType.steps]"
        unit="steps"
        ref="viewAllData"
      />
      <view-periodic-data
        v-else
        :period-type="selectedPeriod"
        :task-types="[TaskType.steps]"
        unit="steps"
        ref="viewPeriodicData"
      />
    </div>
    <add-tracking-button @tracking-added="onTrackingAdded"/>
  </navbar-page>
</template>

<script lang="ts" setup>
import {ref} from "vue";
import NavbarPage from "@/vue/templates/navbar-page.vue";
import {TaskType} from "@/ts/types/dto/activity.dto";
import InputSelect from "@/vue/atoms/input-select.vue";
import {PeriodType} from "@/ts/types/component/input.select";
import ViewPeriodicData from "@/vue/pages/profile/my-progress/view-periodic-data.vue";
import ViewAllData from "@/vue/pages/profile/my-progress/view-all-data.vue";
import AddTrackingButton from "@/vue/atoms/add-tracking-button.vue";

const periodOptions = [
  { label: "Weekly", value: PeriodType.WEEKLY },
  { label: "Monthly", value: PeriodType.MONTHLY },
  { label: "Yearly", value: PeriodType.YEARLY },
  { label: "All", value: PeriodType.ALL_TIME },
];
const viewAllData = ref<InstanceType<typeof ViewAllData> | null>(null);
  const viewPeriodicData = ref<InstanceType<typeof ViewPeriodicData> | null>(null);

const selectedPeriod = ref(PeriodType.WEEKLY)
const onTrackingAdded = async()=> {
await viewPeriodicData.value?.fetchData();
await viewAllData.value?.fetchData();

};

</script>

<style lang="sass" scoped>
.body
  background-color: $color-neutral-platinum-40
  background-size: contain
  color: $color-primary-hc-blue-100
  @include Asap700
  width: 100%
  height: 100%
  min-height: 100vh

.container
  margin: 0 auto
  padding-bottom: 20px
  max-width: 600px

.period-picker
  margin-bottom: 50px

.view-all
  margin: 0 100px
</style>
