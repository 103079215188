import type {Api} from "@/ts/classes/api";
import type {DefaultStore} from "@/ts/store/default/default-store";
import {FetchOnce} from "@/ts/utils/decorators";
import type {Program, RewardsTracker} from "@/ts/types/store/default-store-types";
import type {UserInterestDTO} from "@/ts/types/dto/profile.dto";

export class CachedApi {
  private rewardTrackerExpiryTimeStamp: number = 0;

  private programExpiryTimeStamp: number = 0;

  private interestsExpiryTimeStamp: number = 0;

  /*
   * Time after each cache becomes invalid, 10s is a good random one
   * Things like reward tracker should be updates in some time, after we did a thing that gives reward, E.g. an HRA
   * But we still don't want to respam this request on each page, since it's on a lot of pages, so we use cache for it
   */
  private readonly cacheLiabilityTime: number = 3000;

  constructor(
    private readonly api: Api,
    private readonly defaultStore: DefaultStore,
  ) {
  }

  @FetchOnce
  async getRewardTracker(): Promise<RewardsTracker> {
    if (this.defaultStore.rewardsTracker && Date.now() - this.rewardTrackerExpiryTimeStamp < this.cacheLiabilityTime) {
      return this.defaultStore.rewardsTracker;
    }
    const rewardTracker = await this.api.getRewardTracker();
    this.rewardTrackerExpiryTimeStamp = Date.now();
    this.defaultStore.setRewardsTracker(rewardTracker);
    return rewardTracker;
  }

  @FetchOnce
  async getProgram(): Promise<Program> {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const programCacheLiabilityTime = 1000 * 60 * 60;
    if (this.defaultStore.program && Date.now() - this.programExpiryTimeStamp < programCacheLiabilityTime) {
      return this.defaultStore.program;
    }
    const program = await this.api.getProgram();
    this.programExpiryTimeStamp = Date.now();
    this.defaultStore.setProgram(program);
    return program;
  }

  @FetchOnce
  async getInterestOptions(): Promise<UserInterestDTO[]> {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const programCacheLiabilityTime = 1000 * 60 * 60 * 4;
    if (this.defaultStore.userInterestOptions && Date.now() - this.interestsExpiryTimeStamp < programCacheLiabilityTime) {
      return this.defaultStore.userInterestOptions;
    }
    const interests = await this.api.getFullInterestList();
    this.interestsExpiryTimeStamp = Date.now();
    this.defaultStore.setInterestOptions(interests);
    return interests;
  }

  @FetchOnce
  async meGetInterestOptions(): Promise<UserInterestDTO[]> {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const programCacheLiabilityTime = 1000 * 60 * 60 * 4;
    if (this.defaultStore.userInterestOptions && Date.now() - this.interestsExpiryTimeStamp < programCacheLiabilityTime) {
      return this.defaultStore.userInterestOptions;
    }
    const interests = await this.api.meGetFullInterestList();
    this.interestsExpiryTimeStamp = Date.now();
    this.defaultStore.setInterestOptions(interests);
    return interests;
  }
}
