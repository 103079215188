<template>
  <div class="container">
    <h2>How am I doing this week?</h2>
    <div class="charts">
      <mood-chart/>
      <steps-chart ref="stepsChart"/>
      <minutes-of-activity-chart ref="moaChart"/>
      <sleep-chart ref="sleepChart"/>
      <weight-chart ref="weightChart"/>
      <water-drinking-chart ref="waterChart"/>
      <calories-chart ref="caloriesChart"/>
      <nicotine-use-chart ref="nicotineChart"/>
      <!--      <heart-rate-chart/>-->
    </div>
  </div>
  <add-tracking-button @tracking-added="onTrackingAdded"/>
</template>

<script lang="ts" setup>
import {ref} from "vue";
import StepsChart from "@/vue/molecules/my-progress/steps-chart.vue";
import MinutesOfActivityChart from "@/vue/molecules/my-progress/minutes-of-activity-chart.vue";
import SleepChart from "@/vue/molecules/my-progress/sleep-chart.vue";
import WeightChart from "@/vue/molecules/my-progress/weight-chart.vue";
import WaterDrinkingChart from "@/vue/molecules/my-progress/water-drinking-chart.vue";
import CaloriesChart from "@/vue/molecules/my-progress/calories-chart.vue";
import NicotineUseChart from "@/vue/molecules/my-progress/nicotine-use-chart.vue";
import MoodChart from "@/vue/molecules/my-progress/mood-chart.vue";
import AddTrackingButton from "@/vue/atoms/add-tracking-button.vue";

const stepsChart = ref<InstanceType<typeof StepsChart> | null>(null);
const moaChart = ref<InstanceType<typeof MinutesOfActivityChart> | null>(null);
const sleepChart = ref<InstanceType<typeof SleepChart> | null>(null);
const weightChart = ref<InstanceType<typeof WeightChart> | null>(null);
const waterChart = ref<InstanceType<typeof WaterDrinkingChart> | null>(null);
 const caloriesChart = ref<InstanceType<typeof CaloriesChart> | null>(null);
const nicotineChart = ref<InstanceType<typeof NicotineUseChart> | null>(null);


const onTrackingAdded = async()=> {

    await stepsChart.value?.fetchData();
    await moaChart.value?.fetchData();
    await sleepChart.value?.fetchData();
    await weightChart.value?.fetchData();
   await waterChart.value?.fetchData();
    await caloriesChart.value?.fetchData();
    await nicotineChart.value?.fetchData();

  
};

</script>

<style lang="sass" scoped>
.container
  @include container

.charts
  display: grid
  grid-template-columns: 1fr 1fr
  grid-gap: 20px
  padding-bottom: 50px
</style>
