<template>
  <div class="hello-text">
    <h1>
      <markdown-it>Welcome, {{ profile.firstName }}!</markdown-it>
    </h1>
    <p v-if="profile.motivationQuote" class="motivation-text">
      <markdown-it>
        {{ profile.motivationQuote }}
      </markdown-it>
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { Profile } from "@/ts/types/store/default-store-types";

export default defineComponent({
  name: "GreetingSection",
  props: {
    profile: {
      type: Object as () => Profile,
      required: true,
    },
  },
});
</script>

<style lang="sass" scoped>
.hello-text
  margin-top: 2rem
  margin-left: 17rem

.motivation-text
  margin-top: -10px
  font-size: 15px
  @include Roboto400

@media (max-width: 1500px)
  .hello-text
    margin-left: 1rem
    margin-right: 1rem
    text-align: center

  .motivation-text
    font-size: 14px

@media (max-width: 1200px)
  .hello-text
    margin-left: 0.5rem
    margin-right: 0.5rem

  .motivation-text
    font-size: 13px

@media (max-width: 900px)
  .hello-text
    margin-left: 0.25rem
    margin-right: 0.25rem

  .motivation-text
    font-size: 12px

@media (max-width: 600px)
  .hello-text
    margin-left: 0
    margin-right: 0

  .motivation-text
    font-size: 11px
</style>
