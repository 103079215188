import {Pagination} from "@/ts/types/dto/pagination.dto";

export enum NotificationTarget {
  program_end_soon = "program_end_soon",
  program_end_today = "program_end_today",
  program_start_today = "program_start_today",
  level_change = "level_change",
  activity_starts_today = "activity_starts_today",
  activity_gatekeeper_due = "activity_gatekeeper_due",
  challenge_win = "challenge_win",
  challenge_lose = "challenge_lose",
  activity_proof_accept = "activity_proof_accept",
  activity_proof_decline = "activity_proof_decline",
  cash_request_accept = "cash_request_accept",
  fitbit_access_revoked = "fitbit_access_revoked",
  fitbit_account_deleted = "fitbit_account_deleted",
  activity_end_soon = "activity_end_soon",
  health_tracker_disconnected = "health_tracker_disconnected"
}

export interface NotificationDTO {
  id: string;
  title: string;
  body: string;
  isRead: boolean;
  extrasJSON: object;
  target: NotificationTarget;
  targetId: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

export interface NotificationClusterDTO {
  date: Date;
  items: NotificationDTO[];
  ariaHeadline: string;
  firstItemIndex: number;
}

export interface GetPaginatedNotificationsDTO {
  items: NotificationDTO[];
  pagination: Pagination;
}

export interface NotificationSummaryDTO {
  totalNotifications: number;
  unreadNotifications: number;
}
