<template>
  <div class="nav-buttons">
    <router-link :to="$pagesPath.notifications.main">
      <img src="@/assets/img/notif-bell.svg" class="notification-bell"/>
      <i v-if="hasUnreadNotifications" class="unread-icon"></i>
    </router-link>
    <router-link :to="$pagesPath.profile.main">
      <user-avatar
          size="mid-small"
          :firstname="profile.firstName"
          :lastname="profile.lastName"
          :avatar="profile.avatarPath"
          :is-circular="true"
      />
    </router-link>
  </div>
</template>

<script lang="ts" setup>
import {computed, inject, onMounted, ref} from "vue";
import {Api} from "@/ts/classes/api";
import UserAvatar from "@/vue/atoms/user-avatar.vue";
import {DefaultStore} from "@/ts/store/default/default-store";
import {getModule} from "vuex-module-decorators";
import {vueStore} from "@/ts/store/vue-store";
import type {Profile} from "@/ts/types/store/default-store-types";


const defaultStore: DefaultStore = getModule(DefaultStore, vueStore);
const profile = computed((): Profile => {
  return defaultStore.profile!;
});

const $api: Api = inject("$api")! as Api
const hasUnreadNotifications = ref(false)

onMounted(async () => {
  try {
    const {unreadNotifications} = await $api.getNotificationSummary()
    hasUnreadNotifications.value = unreadNotifications > 0
  } catch (e) {
    console.error(e)
  }
})

</script>
<!-- eslint-disable -->
<style lang="sass" scoped>
.nav-buttons
  display: flex
  margin: 1rem
  gap: 13px

  > *
    margin-right: 0.5rem

.unread-icon
  position: absolute
  left: 17px
  width: 8px
  height: 8px
  background-color: $color-status-error-red
  border-radius: 10px

.notification-bell
  background-color: white
  border-radius: 50%
  padding: 10px

.profile-img
  background-color: white
  border-radius: 50%
  padding: 10px
</style>
