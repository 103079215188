<template>
  <div class="chart">
    <header>
      <icon-chameleon
        :icon="GenericIcon"
        color="secondary100"
        alt="minutes of activity icon"
        aria-hidden="true"
      />
      <h4 class="title">
        <router-link :to="$pagesPath.profile.myMinutesOfActivityProgress">
          Minutes of activity
        </router-link>
      </h4>
    </header>
    <line-chart v-if="hasData && showChart" :data="_chartData" :options="options"/>
    <no-data-placeholder v-else/>
  </div>
</template>

<script setup lang="ts">
import {Chart as ChartJS, PointElement, LinearScale, LineElement} from "chart.js";
import {Line as LineChart} from "vue-chartjs";
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";
import {computed, inject, onMounted, ref} from "vue";
import NoDataPlaceholder from "@/vue/molecules/my-progress/no-data-placeholder.vue";
import IconChameleon from "@/vue/atoms/icon-chameleon.vue";
import GenericIcon from "@/assets/img/icons/generic_black.svg";
import type {Api} from "@/ts/classes/api";
import {
  addToDate,
  convertDateToServerFormat,
  getDayOfWeek,
  getMondayOfTheWeek,
} from "@/ts/utils/date-pure-functions";
import {TaskType} from "@/ts/types/dto/activity.dto";

ChartJS.register(LinearScale, PointElement, LineElement, ChartJsPluginDataLabels);

const $api = inject("$api")! as Api;

const numberOfDays = 7;
const showChart=ref(false);
const startDate: Date = getMondayOfTheWeek(new Date());
const days: Date[] = new Array(numberOfDays)
  .fill("")
  .map((day, index) => addToDate(startDate, {days: index}));
const labels: string[] = days.map((day) => getDayOfWeek(day).substring(0, 3)
.toUpperCase());
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const chartData = ref<any>({labels,
datasets: []});

/* eslint-disable */
const options: any = {
  responsive: true,
  maintainAspectRatio: true,
  elements: {
    point: {
      radius: 5,
      borderWidth: 3,
      borderColor: '#EB61C7',
    },
    line: {
      borderColor: '#9EABBA',
    }
  },
  layout: {
    padding: {
      top: 20
    }
  },
  events: [],
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false
    },
    datalabels: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
      ticks: {
        color: '#9EABBA',
        padding: 10,
      },
    },
    y: {
      display: true,
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
      ticks: {
        color: '#9EABBA',
        padding: 10,
      },
    },
  },
};

onMounted(async () => {
 await fetchData();
})
const fetchData = async () => {
  showChart.value=false;
  try {
    const trackingItems = await $api.getTrackingList({
      startDate: convertDateToServerFormat(days[0]),
      endDate: convertDateToServerFormat(days[days.length-1]),
      taskType: Object.values(TaskType)
          .filter(taskType => taskType.includes('time') || taskType == TaskType.general_physical_activity_time)
          .filter(taskType => taskType != TaskType.sleep_time)
          .join(',')}
    );
    const isoDateToMinutesCount: { [key: string]: number; } = days.reduce((acc,d) => ({...acc,[convertDateToServerFormat(d)]:0}),{})
    trackingItems.forEach(item => isoDateToMinutesCount[item.date] += Math.floor(item.trackedAmount/60))
    const data: number[] = Object.values(isoDateToMinutesCount)
    chartData.value.datasets = [{
      backgroundColor: '#FFFFFF',
      data
    }]
    showChart.value=true;

  } catch (err) {
    // TODO handle error
    console.error(err);
    showChart.value=true;

  }
}
defineExpose({fetchData});


const hasData = computed((): boolean => {
  let sum = 0;
  if (chartData.value) {
    sum = chartData.value.datasets
      .map((dataset: {data: number[]}) => dataset.data.reduce((previousValue, currentValue) => previousValue + currentValue, 0))
      .reduce((previousValue: number, currentValue: any) => previousValue + currentValue, 0);
  }

  return (Boolean(chartData.value) && sum > 0);
});
const _chartData = computed((): any => {
  return chartData.value
});
</script>

<style lang="sass" scoped>
.chart
  padding: 16px
  box-shadow: 0 0 10px rgba(15, 38, 78, 0.15)
  border-radius: 10px
  background-color: $color-white

header
  display: flex
  margin: 10px 0

.title
  font-size: 16px
  text-transform: uppercase
  margin: 0 0 0 10px
  display: flex
  justify-content: center
  align-items: center

.spinner
  @include lds-spinner(10px, "Loading", true)
</style>
