<template>
  <navbar-page 
    title="My Program" 
    :profile-section="true"
    :reward-points="true"
    class="body"
  >
    <program-page-select/>
  </navbar-page>
</template>

<script lang="ts">
import {Component} from "vue-property-decorator";
import ProgramPageSelect from "@/vue/molecules/program-page-select.vue";
import NavbarPage from "@/vue/templates/navbar-page.vue";

import {mixins} from "vue-class-component";
import {mixinClassBodyFactory} from "@/ts/mixins/bg-mixin";

@Component({
  name: "MyProgramPage",
  components: {
    ProgramPageSelect,
    NavbarPage,
  },
})
export default class MyProgramPage extends mixins(mixinClassBodyFactory("global-onboarding-bg-color")) {
}
</script>

<style lang="sass" scoped>
.body
  background-image: url("@/assets/img/background-wave-2.svg")
  background-position-y: -1rem
  background-repeat: no-repeat
  background-size: contain
  color: $color-primary-hc-blue-100
  @include Asap700
  position: absolute
  height: 100%
  width: 100%

</style>