<template>
  <popup-modal :model-value="displayed" @update:model-value="closePopup">
    <div class="content">
      <h3 class="title">
        Forgot my username
      </h3>
      <template v-if="!requestSent">
        <p class="text">
          Type in the <span>email</span> connected to your account and we'll send you a username retrieval email.
        </p>
        <form @submit.prevent="sendUsernameReset">
          <error-text :error="error"/>
          <field-set id="email" class="label" label="My email">
            <input-text
              id="email"
              v-model="email"
              placeholder="Enter your email"
              required
            />
          </field-set>
          <button-primary
            class="button"
            :loading="loading"
            type="submit"
            text="Retrieve username"
            theme="dark"
          />
        </form>
      </template>

      <template v-else>
        <p class="text">
          Your request has been successfully sent. If you did not receive an email, please ensure that the email entered is connected to your myHC360+ account.
        </p>
        <img src="@/assets/img/request-sent.svg" alt="Request sent"/>
        <button-primary class="button" text="Got it!" @click="closePopup"/>
      </template>
    </div>
  </popup-modal>
</template>

  <script lang="ts">
  import {Component, Prop, Emit} from "vue-property-decorator";
  import PopupModal from "@/vue/atoms/popup-modal.vue";
  import ErrorText from "@/vue/atoms/error-text.vue";
  import FieldSet from "@/vue/molecules/field-set.vue";
  import InputText from "@/vue/atoms/input-text.vue";
  import ButtonPrimary from "@/vue/atoms/button-primary.vue";
  import {mixins} from "vue-class-component";
  import {LoadingMixin, DefaultGrowlError} from "@/ts/mixins/loading-mixin";

  @Component({
    name: "ForgotUsernamePopup",
    components: {
      ButtonPrimary,
      ErrorText,
      FieldSet,
      InputText,
      PopupModal,
    }
  })
  export default class ForgotUsernamePopup extends mixins(LoadingMixin) {
    @Prop()
    displayed!: boolean;

    email: string = "";

    requestSent: boolean = false;

    @Emit('closePopup')
    closePopup(): boolean {
      this.requestSent = false;
      return false;
    }

    @DefaultGrowlError
    async sendUsernameReset(): Promise<void> {
      await this.$api.requestUsername({
        "username-email": this.email,
      });
      this.email = "";
      this.requestSent = true;
    }
  }

  </script>

  <style lang="sass" scoped>
  .content
    display: flex
    flex-direction: column
    max-width: 652px

  .title
    @include Asap700
    color: $color-primary-hc-blue-100
    font-size: 28px
    line-height: 36px
    margin-top: 0
    margin-bottom: 16px

  .text
    @include Roboto400
    color: $color-primary-hc-blue-100
    font-size: 16px
    line-height: 24px
    letter-spacing: 0.1px

    span
      @include Roboto700

  form
    display: flex
    flex-direction: column
    margin-top: 40px

  .label
    font-size: 16px
    line-height: 22px
    letter-spacing: 0.15px

  .button
    margin-top: 48px
    align-self: flex-end

  img
    align-self: center
    height: 132px
    width: 183px
  </style>
