import type {GenderType} from "@/ts/types/component/input.select";
import type {UserRole} from "@/ts/types/dto/user.dto";

export enum UserStatus {
  active = "active",
  inactive = "inactive",
  anonymized = "anonymized",
}

export interface Department {
  id: string;
  name: string;
}

export interface Location {
  id: string;
  location: string;
}

export interface GetProfileResponseDTO {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  uniqueId: string;
  companyCode: string;
  avatarPath: string;
  email: string;
  gender: GenderType;
  phoneNumber: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postcode: string;
  motivationQuote: string;
  dateOfBirth: string;
  height: number;
  weight: number;
  role: UserRole;
  companyId: string;
  department: Department | null;
  location: Location | null;
  emailConfirmationStatus: "confirmed" | "not_confirmed" | "pending";
  interests: UserInterestDTO[];
  hideGender?: boolean;
  myCareEnabled: boolean;
}


export interface PutProfileResponse {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  uniqueId: string;
  companyCode: string;
  avatarPath: string;
  email: string;
  gender: GenderType;
  phoneNumber: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postcode: string;
  motivationQuote: string;
  dateOfBirth: string;
  height: number;
  weight: number;
  role: UserRole;
  companyId: string;
  // THIS is how buggy backend works, returning null for FK
  department: null;
  location: null;
  emailConfirmationStatus: "confirmed" | "not_confirmed" | "pending";
  interests: UserInterestDTO[];
}

export interface PutProfileRequestDTO {
  firstName: string;
  lastName: string;
  email: string;
  gender: GenderType;
  phoneNumber: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postcode: string;
  motivationQuote: string;
  dateOfBirth: string;
  height: number;
  uniqueId: string;
  interests: UserInterestDTO[];
}

export interface DeleteAccountRequestDTO {
  dob: string;
}

export interface UserInterestDTO {
  id: string;
  name: string;
}

