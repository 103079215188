<template>
  <navbar-page :title="section.title" :subtitle="pathwayResponse.title" :close-link="true">
    <loading-bar
      :current="sectionNumber"
      class="top"
      theme="pink"
      :steps="sectionsCount"
    />
    <div class="grey-block">
      <div class="image-holder">
        <section-number
          :number="sectionNumber"
          :total="sectionsCount"
          class="section-number"
        />
        <media-reference :object="section.pathwayMedia" class="media-object"/>
      </div>
    </div>
    <div class="content-holder">
      <div class="text">
        <markdown-it :text="section.description"/>
      </div>
      <div v-if="nextSection" class="next-section">
        <button-link
          :to="prevLink"
          :disabled="!prevSection"
          :loading="loading"
          theme="white"
          class="nav-button"
        >
          &lt;&nbsp;&nbsp;Back
        </button-link>
        <button-primary class="nav-button" @click="goNext">
          Next
          <bigger-sign/>
        </button-primary>
      </div>
      <button-primary
        v-else
        :loading="loading"
        class="finish-button"
        @click="finishPath"
      >
        Finish Pathway
      </button-primary>
    </div>
  </navbar-page>
</template>

<script lang="ts">
import {
  Component,
  Prop,
} from "vue-property-decorator";
import LoadingBar from "@/vue/atoms/loading-bar.vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import type {
  GetPathwayResponseDTO,
  Section,
} from "@/ts/types/dto/pathway.dto";

import DropDownItem from "@/vue/molecules/side-bar-item.vue";
import SectionNumber from "@/vue/atoms/section-number.vue";
import YoutubeVideo from "@/vue/molecules/youtube-video.vue";
import MediaReference from "@/vue/molecules/media-reference.vue";
import MarkdownIt from "@/vue/atoms/markdown-it.vue";
import NavbarPage from "@/vue/templates/navbar-page.vue";
import BiggerSign from "@/vue/atoms/bigger-sign.vue";
import {mixins} from "vue-class-component";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import ButtonLink from "@/vue/atoms/button-link.vue";

@Component({
  name: "PathwayTaskPage",
  components: {
    ButtonLink,
    BiggerSign,
    NavbarPage,
    MarkdownIt,
    MediaReference,
    YoutubeVideo,
    SectionNumber,
    DropDownItem,
    ButtonPrimary,
    LoadingBar,
  },
})

export default class PathwayTaskPage extends mixins(LoadingMixin) {
  @Prop()
  pathwayResponse!: GetPathwayResponseDTO;

  get section(): Section {
    return this.pathwayResponse.sections[this.sectionIndex!];
  }

  get imageUrl(): string {
    return this.section.pathwayMedia.mediaReference;
  }

  get prevLink(): string {
    if (this.prevSection) {
      return this.$pagesPath.pathway.pathwayTask
        .replace(":id", this.pathwayResponse.id)
        .replace(":number", `${this.sectionNumber - 1}`);
    }
    // empty link doesn't redirect
    return "";
  }

  get sectionsCount(): number {
    return this.pathwayResponse.sections.length;
  }

  get sectionNumber(): number {
    return parseInt(this.$route.params.number as string, 10);
  }

  get nextSection(): Section | null {
    return this.pathwayResponse.sections[this.sectionIndex + 1] || null;
  }

  get prevSection(): Section | null {
    return this.pathwayResponse.sections[this.sectionIndex - 1] || null;
  }

  get sectionIndex(): number {
    return this.sectionNumber - 1;
  }

  async goNext(): Promise<void> {
    const url = this.$pagesPath.pathway.pathwayTask
      .replace(":id", this.pathwayResponse.id)
      .replace(":number", `${this.sectionNumber + 1}`);
    return this.applyLastReadSection(url);
  }

  @DefaultGrowlError
  async applyLastReadSection(nextUrl: string): Promise<void> {
    if (this.pathwayResponse.lastSectionRead < this.sectionIndex + 1) {
      await this.$api.putLastSectionRead(this.pathwayResponse.id, {
        page: this.sectionIndex + 1,
      });
    }
    void this.$router.push(nextUrl);
  }

  async finishPath(): Promise<void> {
    const nextUrl = this.$pagesPath.pathway.pathwayTaskFinish.replace(":id", this.pathwayResponse.id);
    return this.applyLastReadSection(nextUrl);
  }
}
</script>

<style lang="sass" scoped>
$grey-block-height: 208px
$buttons-top: 64px

.top
  margin-top: 99px

.section-number
  position: absolute
  top: 8px
  left: -120px

.grey-block
  background-color: $color-neutral-platinum-40
  height: $grey-block-height

%center-block
  width: 900px
  margin: auto

.image-holder
  position: relative
  @extend %center-block

.content-holder
  margin-top: 40px
  margin-bottom: 91px
  @extend %center-block

.text
  color: $color-primary-hc-blue-100
  @include Roboto400
  font-size: 16px
  font-weight: 400
  text-align: left
  line-height: 24px
  letter-spacing: 0.1px

.nav-button
  width: 130px

.media-object
  height: $grey-block-height

.media-object:deep(.main-image)
  height: $grey-block-height

h1
  @include Asap700
  size: 40px
  margin: 9px
  // lineheight (6+ 12) /2
  color: $color-primary-hc-blue-100
  text-align: center

.next-section
  margin-top: $buttons-top
  display: flex
  justify-content: space-between

.finish-button
  margin: $buttons-top auto 0 auto
</style>
