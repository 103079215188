<template>
  <div :class="frameClass" :style="sizeStyle" >
    <protected-image
      v-if="image"
      :src="image"
      :aria-hidden="true"
      :alt="props.alt || 'avatar'"
      :size-width="requestedImageSize"
      :resize-to-square="true"
    />

    <img
      v-else-if="fallbackImage"
      :src="fallbackImage"
      aria-hidden="true"
      class="fallback-img"
    />

    <div v-else-if="letters" :class="lettersThemeClass" aria-label="alt">
      <span :style="{'font-size': `${lettersSize}px`}">{{ letters }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type {StyleValue} from "vue";
import {computed} from "vue";
import ProtectedImage from "@/vue/atoms/protected-image.vue";

const DEFAULT_SIZE = 200;

const props = defineProps<{
  image?: string;
  fallbackImage?: string;
  size: number;
  letters?: string;
  lettersTheme?: "default" | "orange";
  isMe?: boolean;
  alt?: string;
  isCircular?: boolean;
}>();

const size = computed(() => {
  return props.size || DEFAULT_SIZE;
});

const lettersSize = computed(() => {
  return size.value * 0.4; // eslint-disable-line @typescript-eslint/no-magic-numbers
});

const frameClass = computed((): string => {
  const border = props.isMe ? "orange" : "default";
  const shape = props.isCircular ? "circular" : "rounded";
  return `frame frame--border-${border} frame--shape-${shape}`;
});

const lettersThemeClass = computed((): string => {
  const theme = props.lettersTheme ?? "default";
  return `letters letters--theme-${theme}`;
});

const requestedImageSize = computed((): number => {
  return size.value * 2; // double fetched images size, looks nicer on high-pixel-density devices.
});

const sizeStyle = computed((): StyleValue => {
  return {
    width: `${size.value}px`,
    height: `${size.value}px`,
  };
});

</script>
<style lang="sass" scoped>
.frame
  &--shape-circular
    border-radius: 50%
  &--shape-rounded
    @include rounded-border-frame
  overflow: hidden
  background-color: $color-neutral-platinum-100
  position: relative

  img:not(.fallback-img)
    background-size: cover
    width: auto
    height: inherit

  &--border-orange
    border: 2px solid $color-tertiary-orange-100


.letters
  width: 100%
  height: 100%
  position: absolute
  display: flex
  justify-content: center
  align-items: center
  @include Roboto400
  font-size: inherit
  line-height: inherit

  &--theme-default
    background-color: $color-primary-hc-blue-50
    color: $color-white

  &--theme-orange
    background-color: $color-tertiary-orange-100
    color: $color-white

.fallback-img
  object-position: center
  object-fit: cover
  width: 100%
  height: 100%

</style>
