<!--eslint-disable max-len-->
<template>
  <register-page
    :prev-page="$pagesPath.auth.additional"
    :current-step="5"
    title="My Interests"
    subtitle="Improving and maintaining your health is easier when you’re engaged! Select what you’re interested in so we can better help you."
    :loading="false"
    @next="submit"
  >
    <interest-list
      :edit-mode="true"
      :interests="poolOfOptions"
      :selected-interests="onboardingStore.interests"
      @select-interest="onSelectInterest"
    />
  </register-page>
</template>

<script lang="ts" setup>
import RegisterPage from "@/vue/templates/register-page.vue";
import {useRouter} from "vue-router";
import {inject, onMounted, ref} from "vue";
import type {PagesPath} from "@/ts/router/pages-path";
import type {UserInterestDTO} from "@/ts/types/dto/profile.dto";
import type {CachedApi} from "@/ts/classes/cached-api";
import InterestList from "@/vue/molecules/interest-list.vue";
import useStoreModule from "@/ts/composables/use-store-module";

const router = useRouter();
const $pagesPath = inject<PagesPath>("$pagesPath")!;
const $cachedApi = inject<CachedApi>("$cachedApi")!;

const {onboardingStore} = useStoreModule();

const poolOfOptions = ref<UserInterestDTO[]>([]);

const submit = (): void => {
  void router.push($pagesPath.auth.motivation);
};

const onSelectInterest = (selectedInterest: UserInterestDTO): void => {
  const alreadySelectedIndex = onboardingStore.interests.findIndex((item) => item.id === selectedInterest.id);

  if (alreadySelectedIndex === -1) {
    onboardingStore.interests.push({
      id: selectedInterest.id,
      name: selectedInterest.name,
    });
  } else {
    onboardingStore.interests.splice(alreadySelectedIndex, 1);
  }
};

onMounted(async() => {
  poolOfOptions.value = await $cachedApi.meGetInterestOptions();
});

if (!onboardingStore.userRegistrationData) {
  void router.push($pagesPath.auth.registration);
}

</script>

<style lang="sass" scoped>

</style>

