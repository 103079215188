<!--eslint-disable max-len-->
<template>
  <svg
    width="366"
    height="305"
    viewBox="0 0 366 305"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    style="padding-bottom: 20px;"
  >
    <defs>
      <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
        <feDropShadow dx="0" dy="4" stdDeviation="4" flood-color="rgba(0, 0, 0, 0.2)" />
      </filter>
      <pattern
        :id="`${uniqueId}-pattern`"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use :xlink:href="`#${uniqueId}`" :transform="transform"/>
      </pattern>
      <image
        :id="uniqueId"
        :xlink:href="imageObjectUrl"
      />
    </defs>
    <path
      d="M462.609 -2H93.2511C93.2511 -2 -51.3911 52 19.6089 205C90.6089 358 222.305 275 295.609 275C368.913 275 423.109 304.5 423.109 304.5L462.609 -2Z"
      :fill="`url(#${uniqueId}-pattern)`"
      filter="url(#shadow)"
    />
  </svg>
</template>

<script lang="ts" setup>
import {computed, inject, onMounted, onUnmounted, ref, watch} from "vue";
import type {Api} from "@/ts/classes/api";

const $api: Api = inject("$api")!;

const imageObjectUrl = ref("");

const props = defineProps<{
  src: string;
}>();

const transform = computed((): string => {
  // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
  const proportion = 1.50980392156;
  const abscise = 0.00075499287;
  const ordinate = abscise * proportion;
  return `translate(0 -0) scale(${abscise} ${ordinate})`;
});

const uniqueId = computed((): string => {
  return `image-${Date.now()}`;
});

const revokeImageData = (): void => {
  if (imageObjectUrl.value) {
    URL.revokeObjectURL(imageObjectUrl.value);
    imageObjectUrl.value = "";
  }
};

const fetchImage = async(): Promise<void> => {
  if (props.src) {
    try {
      const imageProcessingOptions = "resize:true/rt:fill/width:1080/height:864/enlarge:true";
      const image = await $api.getProtectedImage(props.src, imageProcessingOptions);
      imageObjectUrl.value = image.imageObjectUrl;
    } catch (err) {
      // don't display image
    }
  }
};

onMounted(() => {
  void fetchImage();
});

onUnmounted(() => {
  revokeImageData();
});

watch(() => props.src, () => {
  void fetchImage();
});

</script>
