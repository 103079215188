<template>
  <div>
    <div v-if="isMobile && !isOpen" class="hamburger-menu" @click="toggleMenu">
      <img src="@/assets/img/side-menu/hamburger-menu.svg" alt="Menu"/>
    </div>
    <div v-else class="side-bar" @mouseover="handleMouseOver" @mouseleave="handleMouseLeave">
      <div :class="['side-bar-content', isOpen ? 'opened' : 'closed']">
        <div class="links">
          <side-bar-item
            v-for="(item, index) in menuItems"
            :key="index"
            :title="item.title"
            :link="item.link"
            :image="item.image"
            :selected-image="item.selectedImage"
            :is-open="isOpen"
            :selected="item.selected"
          />
        </div>
      </div>
    </div>
    <div v-if="isMobile && isOpen" class="side-bar-mobile">
      <div class="side-bar-content opened">
        <div class="close-menu" @click="toggleMenu">
          <img src="@/assets/img/side-menu/close-menu.svg" alt="Close"/>
        </div>
        <div class="links">
          <side-bar-item
            v-for="(item, index) in menuItems"
            :key="index"
            :title="item.title"
            :link="item.link"
            :image="item.image"
            :selected-image="item.selectedImage"
            :is-open="isOpen"
            :selected="item.selected"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Vue,
} from "vue-property-decorator";
import SideBarItem from "@/vue/molecules/side-bar-item.vue";
import myProgramSvg from "@/assets/img/my-program.svg";
import myProgramSelectedSvg from "@/assets/img/side-menu/my-program-white.svg";
import dashboardSvg from "@/assets/img/my-dashboard.svg";
import dashboardSelectedSvg from "@/assets/img/side-menu/dashboard-white.svg";
import unionSvg from "@/assets/img/union.svg";
import unionSelectedSvg from "@/assets/img/side-menu/union-white.svg";
import myHealthSvg from "@/assets/img/my-health.svg";
import myHealthSelectedSvg from "@/assets/img/side-menu/my-health-white.svg";
import myRewardsSvg from "@/assets/img/my-rewards.svg";
import myRewardsSelectedSvg from "@/assets/img/side-menu/my-rewards-white.svg";

@Component({
  name: "SideBarMenu",
  components: {SideBarItem},
})
export default class SideBarMenu extends Vue {
  isOpen = false;
  isMobile = window.innerWidth <= 900;

  get menuItems() {
    return [
      {
        title: "My Dashboard",
        link: this.$pagesPath.main.dashboard,
        image: dashboardSvg,
        selectedImage: dashboardSelectedSvg,
        selected: this.$route.path === this.$pagesPath.main.dashboard,
      },
      {
        title: "My Program",
        link: this.$pagesPath.program.programOverview,
        image: myProgramSvg,
        selectedImage: myProgramSelectedSvg,
        selected: this.$route.path.includes(this.$pagesPath.program.program),
      },
      {
        title: "My Community",
        link: this.$pagesPath.community.communityFeed,
        image: unionSvg,
        selectedImage: unionSelectedSvg,
        selected: this.$route.path.includes(this.$pagesPath.community.community),
      },
      {
        title: "My Health",
        link: this.$pagesPath.myHealthPath.myhealth,
        image: myHealthSvg,
        selectedImage: myHealthSelectedSvg,
        selected: this.$route.path.includes(this.$pagesPath.myHealthPath.myhealth),
      },
      {
        title: "My Rewards",
        link: this.$pagesPath.main.rewards,
        image: myRewardsSvg,
        selectedImage: myRewardsSelectedSvg,
        selected: this.$route.path === this.$pagesPath.main.rewards,
      },
    ];
  }

  mounted() {
    this.checkScreenWidth();
    window.addEventListener('resize', this.checkScreenWidth);
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenWidth);
  }

  checkScreenWidth() {
    this.isMobile = window.innerWidth <= 900;
    if (!this.isMobile) {
      this.isOpen = window.innerWidth > 1800;
    }
  }

  handleMouseOver() {
    if (window.innerWidth <= 1800 && !this.isMobile) {
      this.isOpen = true;
    }
  }

  handleMouseLeave() {
    if (window.innerWidth <= 1800 && !this.isMobile) {
      this.isOpen = false;
    }
  }

  toggleMenu() {
    this.isOpen = !this.isOpen;
  }
}
</script>

<style lang="sass" scoped>
.side-bar-content
  background-color: #FFFFFF
  box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.25)
  position: fixed
  left: 0
  top: 0
  height: 100%
  transition: width 0.1s ease-in-out, opacity 0.1s ease-in-out
  z-index: 1000

.closed
  width: 100px

.opened
  width: 280px

.links
  padding-top: 20px
  padding-left: 20px

.hamburger-menu
  position: fixed
  top: 20px
  left: 20px
  z-index: 1001
  cursor: pointer

.close-menu
  position: absolute
  top: 20px
  left: 290px
  z-index: 1001
  cursor: pointer

.side-bar-mobile
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: rgba(0, 0, 0, 0.5)
  z-index: 1000

.side-bar-content.opened
  width: 275px
  background-color: #FFFFFF
  box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.25)
  position: fixed
  left: 0
  top: 0
  height: 100%
  transition: width 0.1s ease-in-out, opacity 0.1s ease-in-out
  z-index: 1001
</style>
