<template>
  <loading-suspense :error="error" :loading="loading">
    <main v-if="dataFound" class="content">
      <pathway-card-row
        title="Active"
        :pathways="activePathways"
        :categories="activeCategories"
        @apply-filter="applyActiveFilter"
      />
      <pathway-card-row
        title="Available"
        :pathways="availablePathways"
        :categories="availableCategories"
        @apply-filter="applyAvailableFilter"
      />
      <pathway-card-row
        title="Completed"
        :pathways="completedPathways"
        :categories="completedCategories"
        @apply-filter="applyCompletedFilter"
      />
    </main>
    <empty-state
      v-else
      :image="noPathwaysImage"
      title="No Pathways Available"
      description="Please contact your admin."
    />
  </loading-suspense>
  <add-tracking-button/>
</template>

<script lang="ts" setup>
import type {PathwayFullDto} from "@/ts/types/dto/pathway.dto";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import PathwayCardRow from "@/vue/molecules/pathway-card-row.vue";
import EmptyState from "@/vue/atoms/empty-state.vue";
import AddTrackingButton from "@/vue/atoms/add-tracking-button.vue";

import {computed, inject, onMounted, ref} from "vue";
import type {Api} from "@/ts/classes/api";
import type {ContentCategory} from "@/ts/types/dto/program.dto";
import noPathwayImage from "@/assets/img/challenge-card-icons/person-practicing-yoga.svg";

const $api: Api = inject("$api")! as Api;

const activeCategories = ref<ContentCategory[]>([]);
const availableCategories = ref<ContentCategory[]>([]);
const completedCategories = ref<ContentCategory[]>([]);

const error = ref("");
const loading = ref(true);
const dataFound = ref(true);

const activePathways = ref<PathwayFullDto[]>([]);
const availablePathways = ref<PathwayFullDto[]>([]);
const completedPathways = ref<PathwayFullDto[]>([]);

const noPathwaysImage = computed((): string => {
  return noPathwayImage;
});

const applyActiveFilter = async(categoryIds: string[]): Promise<void> => {
  const filteredPathways = await $api.getPathways({
    onlyActive: true,
    categoryIds: categoryIds.join(","),
    limit: 100,
  });
  activePathways.value = filteredPathways?.items ?? [];
};

const applyAvailableFilter = async(categoryIds: string[]): Promise<void> => {
  const filteredPathways = await $api.getPathways({
    onlyAvailable: true,
    categoryIds: categoryIds.join(","),
    limit: 100,
  });
  availablePathways.value = filteredPathways?.items ?? [];
};

const applyCompletedFilter = async(categoryIds: string[]): Promise<void> => {
  const filteredPathways = await $api.getPathways({
    onlyCompleted: true,
    categoryIds: categoryIds.join(","),
    limit: 100,
  });
  completedPathways.value = filteredPathways?.items ?? [];
};

onMounted(async() => {
  try {
    await loadCategories();

    const [activeResponse, availableResponse, completedResponse] = await Promise.allSettled([
      $api.getPathways({
        onlyActive: true,
        limit: 100,
      }),
      $api.getPathways({
        onlyAvailable: true,
        limit: 100,
      }),
      $api.getPathways({
        onlyCompleted: true,
        limit: 100,
      }),
    ]);

    if (activeResponse.status === "fulfilled") {
      activePathways.value = activeResponse?.value?.items ?? [];
    }

    if (availableResponse.status === "fulfilled") {
      availablePathways.value = availableResponse?.value?.items ?? [];
    }

    if (completedResponse.status === "fulfilled") {
      completedPathways.value = completedResponse?.value?.items ?? [];
    }
  } catch (err) {
    error.value = err as string;
  } finally {
    loading.value = false;
    dataFound.value = Boolean(activePathways.value?.length) ||
    Boolean(availablePathways.value?.length) ||
    Boolean(completedPathways.value?.length);
  }
});

const loadCategories = async(): Promise<void> => {
  const [activeCategoryResponse, availableCategoryResponse, completedCategoryResponse] = await Promise.allSettled([
      $api.getPathwayCategories({
        onlyActive: true,
      }),
      $api.getPathwayCategories({
        onlyAvailable: true,
      }),
      $api.getPathwayCategories({
        onlyCompleted: true,
      }),
    ]);

    if (activeCategoryResponse.status === "fulfilled") {
      activeCategories.value = activeCategoryResponse.value
      .sort((a, b) => b.count - a.count)
      .map((category) => ({id: category.id,
name: `${category.name} (${category.count})`}));
    }
    if (availableCategoryResponse.status === "fulfilled") {
      availableCategories.value = availableCategoryResponse.value
      .sort((a, b) => b.count - a.count)
      .map((category) => ({id: category.id,
name: `${category.name} (${category.count})`}));
    }
    if (completedCategoryResponse.status === "fulfilled") {
      completedCategories.value = completedCategoryResponse.value
      .sort((a, b) => b.count - a.count)
      .map((category) => ({id: category.id,
name: `${category.name} (${category.count})`}));
    }
};

</script>

<style lang="sass" scoped>
main
  margin-top: 3rem

  @media (min-width: 1800px)
    margin-left: 12rem

</style>
